//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'recover',
  data() {
    return {
      isEmailSent: false,
      email: '',
      password: '',
      cpassword: '',
    };
  },
  methods: {},
};
